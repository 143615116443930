import React from 'react'
import { ShippingForm } from '../src'
import projectNames from '../src/configs/projectNames'
import { ShippingFormProps } from '../src/components/ShippingForm/ShippingForm'

export default function Root(props: { properties: ShippingFormProps }) {
  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      <ShippingForm {...props.properties} />
    </section>
  )
}
